import { createGlobalStyle } from 'styled-components'
import tw, { globalStyles } from 'twin.macro'

const GlobalStyles = createGlobalStyle(
  globalStyles,
  `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }

   h1 {
    font-size: 2.25rem;
   }
   h2 {
    font-size: 1.875rem;
   }
   h3 {
    font-size: 1.5rem;
   }
   h4 {
    font-size: 1.25rem;
   }
   h5 {
    font-size: 1.125rem;
   }

`
)

export default GlobalStyles
