import { BlogDetailsData } from '@/types/blog'
import Paths from '@/constants/paths'
import { Page } from '@/components/page/Page'
import { YoutubeEmbed } from '@/components/misc/YoutubeEmbed'
import { ContentContainerMd } from '@/styles/page'
import SEO from '@/utils/SEO'
import { Image } from '@/components/images/Image'
import YborCitySignImg from '@/images/blog/cubanSandwichFestival2024/cuban-sandwich-festival-2024-ybor-city-sign.jpg'
import LineImg from '@/images/blog/cubanSandwichFestival2024/cuban-sandwich-festival-2024-line-for-cuban-sandwich.jpg'
import RoosterImg from '@/images/blog/cubanSandwichFestival2024/cuban-sandwich-festival-2024-rooster.jpg'
import CookingUpImg from '@/images/blog/cubanSandwichFestival2024/cuban-sandwich-festival-2024-cooking-cuban-sandwiches.jpg'
import SeptFestivalImg from '@/images/blog/cubanSandwichFestival2024/cuban-sandwich-festival-sept-2024.jpg'
import EdithTylerImg from '@/images/blog/cubanSandwichFestival2024/cuban-sandwich-festival-2024-edith-tyler-cuban-sandwich.jpg'

export const BlogCubanSandwichFestival2024Details: BlogDetailsData = {
  title: 'Things to Do in Tampa Florida: Cuban Sandwich Festival',
  description: `Discover the 13th Annual Cuban Sandwich Festival, one of the best things to do in Tampa, Florida. Enjoy Cuban sandwiches, live music, and more in Ybor City!

  We ventured to the 13th Annual Cuban Sandwich Festival in Tampa, Florida, on Sunday, May 26, 2024. Held at Centennial Park in Historic Ybor City, the high reached 95 degrees Fahrenheit in our subtropical climate. This event celebrates the Cuban sandwich - one of the city's most beloved culinary treasures. There is a friendly rivalry between the Cuban sandwiches of Miami and those of Tampa. If you’re looking for exciting things to do in Tampa, Florida, this festival is a must-visit. Here you can decide if you prefer salami in your Cuban sandwich.
  `,
  path: Paths.BlogCubanSandwichFestival2024.loc,
  thumbnailImgStr:
    '/images/blog/thumbnail/cuban-sandwich-festival-2024-edith-holding-cuban-sandwich-tb.jpg',
}

export const BlogCubanSandwichFestival2024 = () => {
  return (
    <Page>
      <SEO
        title="Things to Do in Tampa Florida: Cuban Sandwich Festival"
        description="Discover the 13th Annual Cuban Sandwich Festival, one of the best things to do in Tampa, Florida. Enjoy Cuban sandwiches, live music, and more in Ybor City!"
        type="article"
        pagePath={Paths.BlogCubanSandwichFestival2024.loc}
        imgFileName='/blog/thumbnail/cuban-sandwich-festival-2024-edith-holding-cuban-sandwich-tb.jpg' 
      />
      <h1>
        A Flavorful Day at the 13th Annual Cuban Sandwich Festival in Tampa,
        Florida
      </h1>

      <ContentContainerMd>
        <YoutubeEmbed embedId="UDgD393652k?si=Ez99q4LqFc3WssT9" />
      </ContentContainerMd>
      <p>
        We went to the 13th Annual Cuban Sandwich Festival on Sunday, May 26,
        2024. Since we love sandwiches, we decided to go for the first time.
        Plus, Tampa was ready for this food party as temperatures hit 95 degrees
        Fahrenheit in our warm climate. The Cuban Sandwich Festival, while
        honoring one of the city's favorite foods, also rekindles the friendly
        rivalry between Miami and Tampa. So, if you’re looking for fun things to
        do in Tampa, this festival is a must. Here, you can choose if you like
        salami in your Cuban sandwich.
      </p>
      <p>
        Held each year at Centennial Park in Ybor City, the festival is now in
        its 13th year. The event takes place in one of Tampa's most lively and
        historic areas. Ybor City is famous for its shops and vintage stores on
        7th Avenue. Also, this vibrant neighborhood, known for its rich culture
        and lively vibe, is the perfect setting for the festival. With its
        charming streets, unique shops, restaurants, and historic buildings,
        Ybor City offers a glimpse into the past while enjoying the present fun.
        As a result, its warm and welcoming atmosphere makes it an ideal spot
        for the Cuban Sandwich Festival.
      </p>
      <Image 
        src={YborCitySignImg}
        alt="Sign for historic Ybor City, at the Cuban Sandwich Festival"
        caption="Centennial Park in Ybor City"
        maxScreenWidth='md'
      />

      <h3 id="the-cuban-sandwich-contest">The Cuban Sandwich Contest</h3>
      <p>
        The festival's highlight was without a doubt the Cuban Sandwich contest.
        Local and international chefs vied to create the best Cuban sandwich.
      </p>
      <p>
        Cozy Coffee Cafe clinched 1st place for the Tampa Historic Cuban
        Sandwich. It's their fifth consecutive win, proving their perfected
        recipe. With a shop in Temple Terrace, dining there soon is a must—stay
        tuned for our experience.
      </p>
      <p>
        Kim’s Cuban Sandwich Shop in Seffner snagged 1st place in the Popular
        Cuban Sandwich category. As I have tasted their Cuban sandwich before I
        can attest to its fantastic nature, I enjoy their well-dressed
        version.&nbsp;
      </p>
      <p>
        Deviled Pig secured 1st place for the Traditional Cuban Sandwich.
        Deviled Pig takes pride in using Cuban bread from La Segunda in their
        sandwiches.&nbsp;
      </p>
      <p>
        Pan, journeying from Japan, earned 1st place for the Non-Traditional
        Cuban Sandwich.
      </p>
      <p>
        This contest is an exciting event for food lovers seeking things to do
        in Tampa, Florida. It's also quite tasty!
      </p>
      <Image 
        src={LineImg}
        alt="Lines form to taste and enjoy the different flavors of the Cuban Sandwich"
        caption="Lines form to taste and enjoy the different flavors of the Cuban Sandwich"
        maxScreenWidth='md'
      />
      <h3 id="the-cuban-sandwich-a-cultural-icon">
        The Cuban Sandwich: A Cultural Icon
      </h3>

      <p>
        The Cuban sandwich, also known as a “Cubano,” weaves its way into
        Tampa's history. At the soul of the sandwich is Cuban bread. La Segunda
        Bakery in Ybor City describes their Authentic Cuban Bread as “ Crusty on
        the outside, soft on the inside, our century-old authentic Cuban bread
        consists of high-gluten spring wheat flour created specifically for our
        bakery. It also contains a shortening with zero trans fat.” La Segunda
        Bakery has been baking authentic Cuban bread since 1915. In spite of
        whatever obstacle may prevent you from visiting Tampa, you can check out
        their offerings on Gold Belly{' '}
        <a href="https://www.goldbelly.com/restaurants/la-segunda-bakery">
          www.goldbelly.com/restaurants/la-segunda-bakery
        </a>
        . Additionally, ham, roasted pork, Swiss cheese, pickles, salami, and
        mustard compose a Cuban sandwich.&nbsp;
      </p>
      <p>
        The sandwich has origins that can be traced back to the late 1800s to
        early 1900s. Immigrants who worked in Tampa's cigar factories helped
        create it. The sandwich blends Cuban, Spanish, and Italian ingredients.
        Often seen as the birthplace of this tasty treat, Ybor City is Tampa's
        historic Latin Quarter.&nbsp; By the way, cigar bars are very common to
        this day in this historic area.
      </p>
      <p>
        For those interested in the history of the Cuban sandwich, "The Cuban
        Sandwich: A History in Layers" by Andrew T. Huse, Dr. Barbara Cruz, and
        Jeff Houck dives into this subject. Here’s a link to their website:{' '}
        <a href="https://www.cubansandwichbook.com/">The Cuban Sandwich Book</a>
        . History buffs will find learning the origins of the Cuban sandwich
        fascinating. Discovering history is another fun thing to do in Tampa,
        Florida.
      </p>
      <Image 
        src={RoosterImg}
        alt="Chickens are a beloved staple of Ybor City"
        caption="Chickens are a beloved staple of Ybor City"
        maxScreenWidth='md'
        maxHeight='40'
      />

      <h3 id="beyond-the-cuban-sandwich">Beyond the Cuban Sandwich</h3>
      <p>
        But it’s not just about Cuban sandwiches! In this case, the festival
        offers a variety of food. Vendors serve a range of Latin American and
        Caribbean fare. Guests enjoyed tacos, kabobs, paella, and more. From
        Cuban coffee to piña coladas everything to keep you hydrated under the
        sun. Exploring diverse meals is one of the delicious things to do in
        Tampa, Florida.
      </p>
      <h3 id="music-dance-and-art">Music, Dance, and Art</h3>
      <p>
        For the dancers, the festival also featured live music. This year with
        concerts from Orquesta Infinidad, Elevation Band, and Revelacion
        Salsera. Additionally, the main stage featured salsa dance lessons.
        Enjoying live music and dance lessons is one of the fun things to do in
        Tampa, Florida. I highly recommend it if you love cultural experiences.
      </p>
      <p>
        Local artists also sold their creations. It added an artistic touch to
        the festival. Handcrafted jewelry, paintings, and more were available.
        They often reflected the vibrant culture of Tampa. Summing up, shopping
        for unique items is one of the artistic things to do in Tampa, Florida.
      </p>
      <h3 id="more-than-just-a-food-festival">
        More Than Just a Food Festival
      </h3>
      <p>
        The Cuban Sandwich Festival is more than just a food festival. It is a
        celebration of Tampa's diverse cultural heritage. It brings together
        people from different backgrounds. United to celebrate a shared love for
        the Cuban sandwich. It also carries a rich history and promotes local
        businesses. Chefs and food enthusiasts to showcase their talents and
        creativity. Engaging in this cultural celebration is one of the
        enriching things to do in Tampa, Florida.
      </p>
      <Image 
        src={CookingUpImg}
        alt="A chef and cuban sandwich artist cooking up delicious food"
        caption="A chef and cuban sandwich artist cooking up delicious food"
        maxScreenWidth='md'
      />
      <h3 id="our-tasty-adventures">Our Tasty Adventures</h3>
      <p>
        I love sampling all things food. Life is too short not to try
        everything. Tyler on the other hand would be happy with one sandwich.
        Today we tried a Cuban sandwich from Station House BBQ. The sandwich
        press melted the cheese which proved a tasty surprise. We also had a
        snack-size Cubano from The Cracked Pepper Cafe. They offered both the
        traditional version of the sandwich as well as one with lettuce and
        tomato. I can’t say there’s been a sandwich we didn’t like. Sampling
        different Cuban sandwiches is one of the must-do things to do in Tampa,
        Florida.
      </p>
      <h3 id="a-community-celebration">A Community Celebration</h3>
      <p>
        Moreover, it was great to see such a lively event in the community. The
        festival attracts thousands of visitors each year. It boosts business
        for local restaurants and shops. It provides a venue for small
        businesses and food vendors to gain exposure and customers. We will be
        back next year. Participating in such a lively community event is a fun
        thing to do in Tampa, Florida.
      </p>
      <p>
        In conclusion, the Cuban Sandwich Festival in Tampa was a joyous and
        flavorful celebration. The festival honors the city's culinary
        traditions and cultural diversity. It is a great event for anyone who
        loves food, music, and a good time. If you’re looking for things to do
        in Tampa, Florida, this festival is a must. The Cuban Sandwich Festival
        does have a website. You can preview previous years' festivities at the
        Cuban Sandwich Festival. (
        <a href="https://www.cubansandwichfestival.com/">
          www.cubansandwichfestival.com/
        </a>
        ). See you next year!
      </p>
      <Image 
        src={EdithTylerImg}
        alt="Edith and Tyler enjoying a cuban sandwich"
        caption="One of the many delicious sandwiches we got to eat"
        maxScreenWidth='md'
      />
      <h3>Upcoming Cuban Sandwich Things To Do</h3>
      <p>
        If you're seeking an exciting blend of science, culture, and culinary
        delights, mark your calendars for Saturday, September 28th, 2024. The
        Museum of Science and Industry (MOSI) in Tampa Bay is hosting an event
        that promises to tantalize taste buds and ignite curiosity—the Science
        of the Cuban Sandwich Event.
      </p>
      <p>
        Nestled at 4801 E Fowler Ave, Tampa, FL 33617, United States, at the
        picturesque Terrace Park, this event is set to run from 6 to 9 pm. And
        trust us, you won't want to miss it—especially since this event is
        geared toward the 18+ crowd.
      </p>
      <p>
        Tickets for this highly anticipated event are priced at $30 and can be
        purchased online at{' '}
        <a href="https://mosi.org/event/socs/">mosi.org/event/socs/</a>. With
        your ticket in hand, you'll embark on a culinary journey like no other,
        exploring the fascinating science behind one of Tampa's most iconic
        dishes—the Cuban sandwich.
      </p>
      <Image 
        src={SeptFestivalImg}
        alt="Information for the upcoming Cuban Sandwich Festival in September 2024"
        maxScreenWidth='md'
      />
      <p>
        So, whether you're a food enthusiast, a science buff, or simply someone
        looking for a unique and unforgettable experience, join us at MOSI on
        September 28th for an evening of exploration, discovery, and, of course,
        deliciousness. We can't wait to see you there!
      </p>
      <p>
        The Museum of Science and Industry
        <br />
        Saturday, Sep 28, 2024 from 6 - 9pm
        <br />
        4801 E Fowler Ave
        <br />
        Tampa, FL 33617
        <br />
        United States
      </p>
      <p>
        Tickets $30 can be purchased at{' '}
        <a href="https://mosi.org/event/socs/">https://mosi.org/event/socs/</a>
      </p>
    </Page>
  )
}
