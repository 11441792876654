import styled from 'styled-components'
import tw from 'twin.macro'
import { BlogDetailsData } from '@/types/blog'
import { NewLineText } from '@/helpers/NewLineText'

const Styles = styled.div`
  ${tw`
    flex flex-col 
    md:flex-row
    [align-items: center]
    gap-4
    py-5
  `}

  .thumbnail_container {
    min-height: 14rem;
    min-width: 16rem;
    margin-bottom: 1rem;

    img {
      max-height: 18rem;
      max-width: 14rem;
      ${tw`m-auto mt-3`}
    }
  }

  h2 {
    ${tw`text-left mb-2 mt-1`}
  }

  p {
    ${tw`mb-1 text-lg`}
  }

  .content_description > p {
    ${tw`mb-5`}

    :last-child {
      ${tw`mb-3`}
    }
  }

  .content_link {
    ${tw`italic`}
  }
`

interface BlogDetailsProps {
  details: BlogDetailsData
}

export const BlogDetails = ({ details }: BlogDetailsProps) => {
  const { title, description, path, thumbnailImgStr } = details

  return (
    <>
      <Styles>
        <div className="thumbnail_container">
          <a href={path}>
            <img
              src={thumbnailImgStr}
              alt={`Thumbnail image for blog post ` + title}
            />
          </a>
        </div>
        <div className="content_container">
          <h2><a href={path}>{title}</a></h2>
          <div className="content_description">
            <NewLineText>{description}</NewLineText>
          </div>
          <a className="content_link" href={path}>
            Read More
          </a>
        </div>
      </Styles>
      <hr />
    </>
  )
}
