import { useEffect } from 'react'
import ReactGA4 from 'react-ga4'
import { Env } from '@/constants/env'

const initializeGoogleAnalytics = (): void => {
  const tracking_id = 'G-K0K9VZTF9L'
  ReactGA4.initialize(tracking_id)
  console.log('GA INITIALIZED')
}

const checkProduction = (): boolean => {
  if (process.env.REACT_APP_NODEENV === Env.Prod) {
    return true
  }
  return false
}

const useInitializeGoogleAnalytics = () => {
  useEffect(() => {
    if (checkProduction()) initializeGoogleAnalytics()
    else console.log('GA NOT INITIALIZED')
  }, [])
}

export {
  initializeGoogleAnalytics,
  checkProduction,
  useInitializeGoogleAnalytics,
}
