import styled from 'styled-components'
import tw from 'twin.macro'
import { Page } from '@/components/page/Page'
import SEO from '@/utils/SEO'
import { DefaultPageStyles } from '@/styles/page'
import { BlogDetails } from '@/components/blog/BlogDetails'
import { BlogCubanSandwichFestival2024Details, BlogPropertyTaxes2024Details } from './blog'
import { AboutDetails } from './AboutPage'

const BlogStyling = styled(DefaultPageStyles)`
  h3 {
    ${tw`text-left`}
  }
`

export const BlogPage = () => {
  return (
    <Page>
      <SEO 
        title='Blog | Edith Austin Realtor'
        description='Discover information about realty and events in Tampa Bay, Florida'
        type='website'
      />
      <BlogStyling>
        <h1>Blog</h1>
        <BlogDetails 
          details={BlogPropertyTaxes2024Details}
        />
        <BlogDetails 
          details={BlogCubanSandwichFestival2024Details}
        />
        <BlogDetails 
          details={AboutDetails}
        />
      </BlogStyling>
    </Page>
  )
}
