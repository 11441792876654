import React, { useEffect, useRef } from "react";

type FlourishEmbedProps = {
  src: string;
};

const FlourishEmbed: React.FC<FlourishEmbedProps> = ({ src }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Dynamically load the Flourish embed script
    const script = document.createElement("script");
    script.src = "https://public.flourish.studio/resources/embed.js";
    script.async = true;

    // Append script to the container
    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }

    return () => {
      // Clean up the script when the component unmounts
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, []);

  return (
    <div ref={containerRef} className="flourish-embed" data-src={src}>
      <noscript>
        <img
          src={`https://public.flourish.studio/${src}/thumbnail`}
          width="100%"
          alt="visualization"
        />
      </noscript>
    </div>
  );
};

export default FlourishEmbed;