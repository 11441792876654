import tw from 'twin.macro'
import styled from 'styled-components'

export const DefaultPageStyles = styled.div`
  h1, h2, h3, h4, h5, p {
    ${tw`
      mt-6 mb-6
    `}
  }
  h1 {
    ${tw`
      text-primary-700
      text-center
    `}
  }
  h2, h3, h4, h5 {
    ${tw`
      text-center
      text-primary-500
      mt-12
    `}
  }
  p, ul, li {
    ${tw`
      md:text-xl
  `}
  }
  a {
    ${tw`cursor-pointer text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`}
  }
  ul, li {
    ${tw`
      mb-2
    `}
  }
`