import tw from 'twin.macro'
import styled from 'styled-components'
import { Page } from '@/components/page/Page'
import { YoutubeEmbed } from '@/components/misc/YoutubeEmbed'
import SEO from '@/utils/SEO'
import * as Styles from '@/styles/page'
import edithBoatPhoto from '@/images/blog/edith-driving-boat-with-dogs.jpg'
import edithRiverPhoto from '@/images/blog/edith-at-hillsborough-river-st-paddys.jpg'
import GoogTranslateButton from '@/components/language/GoogTranslationBtn'
import Paths from '@/constants/paths'
import { BlogDetailsData } from '@/types/blog'

export const AboutDetails: BlogDetailsData = {
  title: 'About Edith Austin - Realtor in Tampa Bay',
  description: `Hello, I'm Edith Austin and I'm passionate about helping people find their dream homes and investments in the Tampa Bay.\nMy journey into the world of real estate began with a personal quest to find a new home, which sparked a ingrained passion for the field. I traveled and searched the country looking for a new place to find home. Along the way I witnessed firsthand the changes and dynamics of the market across different regions, especially during the transition from California to Florida. This ignited my fascination with real estate even further.`,
  path: Paths.About.loc,
  thumbnailImgStr: '/images/blog/thumbnail/edith-ybor-city-columbia.JPG',
}

const ColumnsTwoUp = styled.div`
  ${tw`
    grid grid-cols-2 gap-4
    justify-items-center
    my-4
  `}

  img {
    max-height: 27rem;
  }
`

const Equation = styled.div`
  ${tw`
     max-w-screen-lg mx-auto
     grid grid-cols-1 md:grid-cols-2 
     justify-center items-center 
     gap-4
     text-blue-800
  `}
  h4 {
    ${tw`text-2xl
      text-center md:text-right
      mb-0
    `}
  }
  p {
    ${tw`text-3xl italic pb-0 mb-0
      text-center md:text-left
    `}
  }
`

export const AboutPage = () => {

  return (
    <Page>
      <GoogTranslateButton />
      <SEO
        title="About Edith Austin - Realtor in Tampa Bay"
        description="Let Edith Austin guide you in finding your perfect home or investment in Tampa Bay. With expertise in mathematics and a commitment to personalized service, she offers unique insights and tailored strategies for each client."
        type="article"
        pagePath={Paths.About.loc}
      />
      <h1>A Dedicated Real Estate Agent in Beautiful Tampa Bay, Florida</h1>

      <p>
        Hello, I'm Edith Austin and I'm passionate about helping people find
        their dream homes and investments in the Tampa Bay.
      </p>
      <br />

      <Styles.IframeContainer>
        <YoutubeEmbed embedId="c6gxJ2SeX6I?si=0WfzD85SVudyJtul" />
      </Styles.IframeContainer>

      <p>
        My journey into the world of real estate began with a personal quest to
        find a new home, which sparked a ingrained passion for the field. I
        traveled and searched the country looking for a new place to find home.
        Along the way I witnessed firsthand the changes and dynamics of the
        market across different regions, especially during the transition from
        California to Florida. This ignited my fascination with real estate even
        further.
      </p>

      <p>
        With a solid foundation in mathematics and a keen eye for the nuances of
        the real estate market, I bring a unique blend of analytical skills and
        a personal touch to my clients' experiences.
      </p>
      <br />

      <h3>
        <strong>Whether You're a Numbers Enthusiast</strong>
      </h3>

      <p>
        Simply appreciate having access to detailed information, or you want
        someone else to look at the numbers for you, my knack for turning
        complex data into clear, actionable insights gives me a unique view in
        the industry.
      </p>

      <p>
        Graduating with a Bachelors of Science in applied mathematics from
        California State University of Northridge, my mathematical background
        gives me the ability to dissect and analyze vast amounts of data,
        providing my clients with insightful reports and forecasts that can
        significantly influence their decision-making process.
      </p>

      <p>
        At the heart of my approach to real estate is a commitment to
        personalized service. I believe that every client's journey to finding
        their perfect home or investment property is unique, and I tailor my
        strategies accordingly. From understanding individual preferences and
        financial capabilities to navigating the complexities of the market in
        Tampa Bay, I strive to create a tailored experience that meets every
        clients' specific needs.
      </p>

      <Styles.VerticalMargin>
        <Equation>
          <h4>Mortgage payment equation:</h4>
          <p>P [ i(1 + i)^n ] / [ (1 + i)^n – 1] = M</p>
        </Equation>
      </Styles.VerticalMargin>

      <p>
        For those seeking a reliable and knowledgeable real estate agent in
        Florida, my goal is to empower my clients with the knowledge and
        confidence needed to navigate the real estate market effectively.
      </p>

      <p>
        Whether you're a first-time buyer, seasoned investor, or somewhere in
        between, I'm eager to discuss your real estate aspirations and explore
        how I can assist you in achieving them.
      </p>

      <br />
      <h3>Interests and Hobbies</h3>

      <p>
        I enjoy exploring the Tampa Bay area in a lot of different ways. I like
        food and cooking and I'm always on the lookout for new food to try. I
        enjoy being on the water and I'm a certified sailor :) <br />I like to
        travel with my dogs and my fiance, I'm always up for a good road trip. I
        also like to garden and ride my motorcycle.
      </p>

      <ColumnsTwoUp>
        <img src={edithBoatPhoto} alt="Edith on a boat ride with her two dogs in Tampa Bay" />
        <img src={edithRiverPhoto} alt="Hillsborough River in Tampa Bay died green for Saint Patrick's Day" />
      </ColumnsTwoUp>
      <br />
      <br />
      <p>
        Please get in touch with me today. You can reach me at 805-765-1625 or
        via email at Edith@EdithAustin.com.
      </p>
    </Page>
  )
}
