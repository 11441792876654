import { HomeData, HomeDetails } from '@/types/homes'
// import Paths from '@/constants/paths'
import { SEOProps } from '@/types/seo'
import { homeAddressStr } from '@/helpers/homes'

// const path = Paths.Home10208RedCurrant.loc

export const data: HomeData = {
  mlNumber: 'U8241836',
  status: 'ACT',
  address: '10208 RED CURRANT CT',
  city: 'RIVERVIEW',
  zip: '33578',
  legalSubdivisionName: 'VENTURA BAY TOWNHOMES',
  subdivisionCondoName: '',
  heatedArea: '1,622',
  currentPrice: '$299,500',
  beds: 3,
  fullBaths: 2,
  halfBaths: 1,
  yearBuilt: 2009,
  pool: 'Private, Community',
  propertyStyle: 'Townhouse',
  tax: '$4,623.35',
  cdom: 21,
  adom: 21,
  daysToContract: '',
  soldTerms: '',
  closeDate: '',
  lpPerSqFt: '$184.65',
  spPerSqFt: '',
  spPerLp: '',
  listOffice: 'REAL BROKER, LLC',
  listAgent: 'Matt Mayers',
  listAgentId: '260045041',
  sellingOfficeId: '',
  lscListSide: 'Pinellas Suncoast',
  listOfficePrimaryBoardId: 'Orlando Regional',
}

export const details: HomeDetails = {
  // path,
  path: '',
  sellingPoint: 'community pool in beautiful Riverview',
  thumbnailImgStr: '/images/homes/thumbnail/home-10208RedCurrant-tn.jpg',
  info: 'Check out this home today by calling (805) 765-1625 or Matt Mayers (727) 678-1074',
}

export const seo: SEOProps = {
  title: `View home ${homeAddressStr(data)}`,
  description:
    'Welcome to the beautiful Ventura Bay Townhome at 10208 Red Currant Ct, Riverview, Florida. This charming townhouse, built in 2009, offers 1,622 square feet of comfortable living space. Listed at $299,500.',
  type: 'article',
  // pagePath: path
}
