import { BasePage } from './BasePage'
import {
  DefaultPageStyles,
  ContentContainer,
} from '../../styles/page'

interface PageProps {
  children: React.ReactNode | React.ReactNode[]
}

export const Page = ({ children }: PageProps) => {
  return (
    <BasePage>
      <ContentContainer>
        <DefaultPageStyles>{children}</DefaultPageStyles>
      </ContentContainer>
    </BasePage>
  )
}
