import tw from 'twin.macro'
import styled from 'styled-components'
import { Nav, NavContainer } from '@/components/navbar'
import Footer from '@/components/footers/Footer'

// Must only have two sections because of flexbox (content and footer sections)
const ContentWithGrowth = styled.div`
  ${tw`
    overflow-hidden
    flex flex-col
    min-h-screen
    mx-auto
    pt-6 lg:pt-10
  `}
`
const ContentSection = tw.div`grow mb-20`

interface PageProps {
  children: React.ReactNode | React.ReactNode[]
}

export const BasePage = ({ children }: PageProps) => {
  return (
    <div>
      <NavContainer>
        {/* @ts-ignore */}
        <Nav />
      </NavContainer>
      <ContentWithGrowth>
        <ContentSection>{children}</ContentSection>
        <Footer />
      </ContentWithGrowth>
    </div>
  )
}
