interface NewLineTextProps {
  children: string
  className?: string
}
export const NewLineText = ({ children, className }: NewLineTextProps) =>
  children.split('\n').map((str, i) => (
    <p className={className} key={'text-gen-' + i}>
      {str}
    </p>
  ))
