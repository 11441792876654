import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { checkProduction } from './analytics'
import { siteURL } from '@/config'
import { SEOProps } from '@/types/seo'

const isProduction = checkProduction()

const SEO = ({
  title,
  description,
  type,
  keywords, // ex: "SEO, learning, keywords"
  indexAndFollow = isProduction,
  pagePath,
  imgFileName,
}: SEOProps) => {
  const [robots, setRobots] = useState('')

  useEffect(() => {
    // Automatically displays robots properties in production, unless explicitly stated not to
    setRobots(indexAndFollow ? 'index, follow' : 'noindex, nofollow')
    if (!isProduction) setRobots('noindex, nofollow')
  }, [])

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="robots" content={robots} />
      <meta name="viewport" content="width=device-width,initial-scale=1"></meta>

      {/* Facebook tags */}
      {type && <meta property="og:type" content={type} />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={pagePath ? siteURL + pagePath : ''} />
      <meta
        property="og:image"
        content={imgFileName ? `${siteURL}/images/${imgFileName}` : ''}
      />

      {/* Twitter tags */}
      <meta name="twitter:creator" content={'Edith Austin'} />
      {type && <meta name="twitter:card" content={type} />}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

export default SEO
