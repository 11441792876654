import { contactInqueryProdURL } from '@/config'

// Body = {subject, body}
export const sendContactInquiry = async body => {
  const result = await fetch(contactInqueryProdURL, {
    method: 'POST',
    body: JSON.stringify(body),
  })

  if (!result.ok) {
    throw new Error('Could not send email')
  }

  return await result.json()
}
