import { PrimaryButton } from '@/components/misc/Buttons'
import { useLocation } from 'react-router-dom'
import checkOnGoogTransPage from '@/utils/googTranslate.ts'
import { checkProduction } from '@/utils/analytics'

const GoogTranslateButton: () => boolean | JSX.Element = () => {
  const location = useLocation()

  const openGoogleTranslate = (): void => {
    const page = location.pathname.slice(1)
    let targetUrl = `https://dev-edithaustin-com.translate.goog/${page}?_x_tr_sl=auto&_x_tr_tl=es&_x_tr_hl=es&_x_tr_pto=wapp`
    if (checkProduction()) {
      targetUrl = `https://edithaustin-com.translate.goog/${page}?_x_tr_sl=auto&_x_tr_tl=es&_x_tr_hl=es&_x_tr_pto=wapp`
    }
    window.location.replace(targetUrl)
  }

  const googTranslateBtn = (
    <PrimaryButton
      onClick={() => {
        openGoogleTranslate()
      }}
    >
      Ve en Espanol
    </PrimaryButton>
  )

  return checkOnGoogTransPage() && googTranslateBtn
}

export default GoogTranslateButton
