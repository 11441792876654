import { HomeData } from "@/types/homes"

export const homeAddressStr = (details: HomeData) => {
  const {address, city, zip} = details

  return `${address}, ${city}, FL ${zip}`
}

// ex return: 2/1 
export const bathroomFormatterStr = (details: HomeData) => {
  const {fullBaths, halfBaths} = details;
  return `${fullBaths}${halfBaths && `/${halfBaths}`}`
}