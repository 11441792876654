export const siteURL = 'https://edithaustin.com'

export const testLambdaURL =
  'https://is4fma6mxjoz56ntgopd7ogmfm0sjtll.lambda-url.us-east-1.on.aws/'

export const contactInqueryDevURL =
  'https://zmkzvyzx3k7z6uchzna6f75siy0dhomu.lambda-url.us-east-1.on.aws/'

export const contactInqueryProdURL =
  'https://ft7zwrayir26hk725ytebafzcu0pazno.lambda-url.us-east-1.on.aws/'
