import { Page } from '@/components/page/Page'
import GoogTranslateButton from '@/components/language/GoogTranslationBtn'

export const TestPage = () => {
  return (
    <Page>
      {<GoogTranslateButton />}
      <br></br>
      <br></br>
      <br></br>
      "This is a test page"
    </Page>
  )
}
