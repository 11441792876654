import tw from 'twin.macro'

export const ContentContainer = tw.div`
  max-w-screen-lg
  mx-auto
  px-3 md:px-5 xl:px-0
`

export const ContentContainerMd = tw.div`
  max-w-screen-md
  mx-auto
  px-3 md:px-5 xl:px-0
  pt-2 pb-8
`

export const ContentContainerXl = tw.div`
  max-w-screen-xl
  mx-auto
  px-3 md:px-5 xl:px-0
`

export const VerticalMargin = tw.div`
  mt-6 !mb-12
`

export const IframeContainer = tw.div`
  max-w-screen-md mx-auto
  mb-8
`