export enum Social {
  Facebook = 'https://www.facebook.com/EdithAustinRealEstate',
  Instagram = 'https://www.instagram.com/realtoredithaustin',
  Youtube = 'https://www.youtube.com/@RealtorEdith',
  Linkedin = 'https://www.linkedin.com/in/realtoredith',
  Snapchat = 'https://twitter.com/edithgrealtor',
  GoogleMapsBusiness = 'https://maps.app.goo.gl/JM1ngmDxuEjwAqRLA',
  Yelp = 'https://yelp.to/DHQkmzSark',
  Zillow = 'https://www.zillow.com/profile/edithguzmanrealtor',
  Realtor = 'https://www.realtor.com/realestateagents/65cd441f0abc487989a09605',
  WhatsApp = 'https://call.whatsapp.com/video/tpo9TOyZCV7dsP1rEwU7wk'
}
