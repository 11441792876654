import { HomeData } from '@/types/homes'
import styled from 'styled-components'
import tw from 'twin.macro'
import { bathroomFormatterStr } from '@/helpers/homes'

const Styles = styled.div`
  table, tbody {
    display:block;
  }
  tr {
    td:first-child  {
      min-width: 10rem;
      font-weight: bold;
    }
  },
  td {
    
  }

`

const GridRow2 = tw.div`grid grid-cols-1 md:grid-cols-2 gap-4 mb-5`
const GridCol = tw.div``;

export const HomeDetailsTable = ({data}: {data: HomeData}) => {
  return (
    <Styles>
      <h2>Home Details</h2>
      <table>
        <tbody>
          <GridRow2>
            <GridCol>
              <tr>
                <td>ML Number</td>
                <td>{data.mlNumber}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{data.address}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{data.city}</td>
              </tr>
              <tr>
                <td>Zip</td>
                <td>{data.zip}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>Florida</td>
              </tr>
              <tr className='price'>
                <td>Price</td>
                <td><strong>{data.currentPrice}</strong></td>
              </tr>
            </GridCol>
            <GridCol>
              <tr>
                <td>Status</td>
                <td>{data.status}</td>
              </tr>
              <tr>
                <td>Subdiv.</td>
                <td>{data.legalSubdivisionName}</td>
              </tr>
              <tr>
                <td>Square ft.</td>
                <td>{data.heatedArea}</td>
              </tr>
              <tr>
                <td>Bedrooms</td>
                <td>{data.beds}</td>
              </tr>
              <tr>
                <td>Baths</td>
                <td>{bathroomFormatterStr(data)}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>{data.yearBuilt}</td>
              </tr>
            </GridCol>
          </GridRow2>
          <GridRow2>
            <GridCol>
              <tr>
                <td>Property Style</td>
                <td>{data.propertyStyle}</td>
              </tr>
              <tr>
                <td>Pool</td>
                <td>{data.pool}</td>
              </tr>
              <tr>
                <td>Price per sqft</td>
                <td>{data.lpPerSqFt}</td>
              </tr>
              <tr>
                <td>Est. tax</td>
                <td>{data.tax}</td>
              </tr>
            </GridCol>
            <GridCol>
              <tr>
                <td>Listing Agent</td>
                <td>{data.listAgent}</td>
              </tr>
              <tr>
                <td>List Agent ID</td>
                <td>{data.listAgentId}</td>
              </tr>
              <tr>
                <td>List Office</td>
                <td>{data.listOffice}</td>
              </tr>
            </GridCol>
          </GridRow2>
        </tbody>
      </table>
    </Styles>
  )
}
